<template>

</template>

<script>
  export default {
    name: 'StyleTemplateEditPanel',
    data () {
      return {}
    }
  }
</script>

<style scoped>

</style>
